export default {
    data() {
        return {
            precision1AccidentConst: [
                {label: this.$t('precisions.1.accident.materiel'), value: 'MATERIEL'},
                {label: this.$t('precisions.1.accident.corporel'), value: 'CORPOREL'},
                {label: this.$t('precisions.1.accident.mortel'), value: 'MORTEL'},
            ],
        }
    },
    methods: {
        displayForPrecision1AccidentConst(value) {
            let result = this.precision1AccidentConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision1AccidentConst() {
            return this.precision1AccidentConst;
        }
    }
}