export default {
    data() {
        return {
            precision2VehiculeGenantConst: [
                {label: this.$t('precisions.2.vehicule_genant.vehicule_en_difficulte'), value: 'VEHICULE_EN_DIFFICULTE'},
                {label: this.$t('precisions.2.vehicule_genant.vehicule_non_autorise'), value: 'VEHICULE_NON_AUTORISE'},
                {label: this.$t('precisions.2.vehicule_genant.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForPrecision2VehiculeGenantConst(value) {
            let result = this.precision2VehiculeGenantConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision2VehiculeGenantConst() {
            return this.precision2VehiculeGenantConst;
        }
    }
}