<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: false,
    },
    mask: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    displayer: {
      type: Object,
      required: false,
      default: undefined
    },
  },
  methods: {},
  computed: {
    currentValue: {
      get() {
        if(this.displayer) return this.displayer(this.modelValue);
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">
    <AkLabel :required="isRequired" :style="this.label === '' || this.label === undefined ? 'display: none;' : ''">{{ this.label }}</AkLabel>
    <InputMask v-model="currentValue" :mask="mask"
        :class="{'p-invalid':isInvalid && alreadySubmitted}"
        :disabled="isDisabled"
        :placeholder="getPlaceholder"
        class="form-control"/>
  </div>
</template>