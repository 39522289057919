export default {
    data() {
        return {
            precision2ChausseeGlissanteProduitsRouteConst: [
                {label: this.$t('precisions.2.chaussee_glissante_produits_route.eau'), value: 'EAU'},
                {label: this.$t('precisions.2.chaussee_glissante_produits_route.hydrocarbures'), value: 'HYDROCARBURES'},
                {label: this.$t('precisions.2.chaussee_glissante_produits_route.produits_chimiques_toxiques'), value: 'PRODUITS_CHIMIQUES_TOXIQUES'},
                {label: this.$t('precisions.2.chaussee_glissante_produits_route.graviers'), value: 'GRAVIERS'},
                {label: this.$t('precisions.2.chaussee_glissante_produits_route.boue'), value: 'BOUE'},
            ],
        }
    },
    methods: {
        displayForPrecision2ChausseeGlissanteProduitsRouteConst(value) {
            let result = this.precision2ChausseeGlissanteProduitsRouteConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision2ChausseeGlissanteProduitsRouteConst() {
            return this.precision2ChausseeGlissanteProduitsRouteConst;
        }
    }
}