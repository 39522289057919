export default {
    data() {
        return {
            precision1BouchonConst: [
                {label: this.$t('precisions.1.bouchon.ralentissement'), value: 'RALENTISSEMENT'},
                {label: this.$t('precisions.1.bouchon.circulation_accordeon'), value: 'CIRCULATION_ACCORDEON'},
                {label: this.$t('precisions.1.bouchon.circulation_arretee'), value: 'CIRCULATION_ARRETEE'},
            ],
        }
    },
    methods: {
        displayForPrecision1BouchonConst(value) {
            let result = this.precision1BouchonConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision1BouchonConst() {
            return this.precision1BouchonConst;
        }
    }
}