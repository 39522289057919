export default {
    mixins: [

    ],
    data() {
        return {
            precision1ObstacleMobileFixeConst: [
                {label: this.$t('precisions.1.obstacle_mobile_fixe.chaussee_glissante_produits_route'), value: 'CHAUSSEE_GLISSANTE_PRODUITS_ROUTE'},
                {label: this.$t('precisions.1.obstacle_mobile_fixe.evenements_inopines'), value: 'EVENEMENTS_INOPINES'},
                {label: this.$t('precisions.1.obstacle_mobile_fixe.obstacles'), value: 'OBSTACLES'},
                {label: this.$t('precisions.1.obstacle_mobile_fixe.pieton_cycliste'), value: 'PIETON_CYCLISTE'},
                {label: this.$t('precisions.1.obstacle_mobile_fixe.vehicule_genant'), value: 'VEHICULE_GENANT'},
                {label: this.$t('precisions.1.obstacle_mobile_fixe.vehicule_contresens'), value: 'VEHICULE_CONTRESENS'},
                {label: this.$t('precisions.1.obstacle_mobile_fixe.vehicule_arrete'), value: 'VEHICULE_ARRETE'},
            ],
        }
    },
    methods: {
        displayForPrecision1ObstacleMobileFixeConst(value) {
            let result = this.precision1ObstacleMobileFixeConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision1ObstacleMobileFixeConst() {
            return this.precision1ObstacleMobileFixeConst;
        }
    }
}