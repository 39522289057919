import Api from "@/services/api";
import {downloadData, removeEmptyParams} from "@utils";

export default {
    findAllIntervention(filter) {
        return Api().get("/interventions", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res;
        });
    },
    findAllInterventionByUserId(userId,filter) {
        return Api().get("/interventions/user/" + userId, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res;
        });
    },
    findAllInterventionByCeiId(ceiId,filter) {
        return Api().get("/interventions/cei/" + ceiId, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res;
        });
    },
    findAllInterventionByUerId(uerId,filter) {
        return Api().get("/interventions/uer/" + uerId, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res;
        });
    },
    findAllInterventionByAgerId(agerId,filter) {
        return Api().get("/interventions/ager/" + agerId, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res;
        });
    },
    updateIntervention(interventionDataDTO, files) {
        let formData = new FormData();
        formData.append(
            'item',
            new Blob([JSON.stringify(interventionDataDTO)], {
                type: 'application/json',
            })
        );

        if (null != files) {
            files.forEach(file => {
                formData.append('files', file);
            });
        }

        return Api().put("/intervention", formData).then(res => {
            return this.formatRes(res.data);
        });
    },
    getInterventionAgents(interventionId) {
        return Api().get("/intervention/" + interventionId + "/agents").then(res => {
            return res.data;
        });
    },
    getInterventionAgentIds(interventionId) {
        return Api().get("/intervention/" + interventionId + "/agentIds").then(res => {
            return res.data;
        });
    },
    findInterventionById(interventionId) {
        return Api().get("/intervention/" + interventionId).then(res => {
            return this.formatRes(res.data);
        });
    },
    deleteIntervention(interventionId) {
        return Api().delete("/intervention/" + interventionId );
    },
    createIntervention(interventionDataDTO, files) {
        let formData = new FormData();
        formData.append(
            'item',
            new Blob([JSON.stringify(interventionDataDTO)], {
                type: 'application/json',
            })
        );

        if (null != files) {
            files.forEach(file => {
                formData.append('files', file);
            });
        }

        return Api().post("/intervention", formData).then(res => {
            return this.formatRes(res.data);
        });
    },
    checkFieldsSize(intervention) { // Retourner un dictionnaire avec les champs qui dépassent pour les ajouter dans les erreurs
        let errors = {}
        if (intervention.actionsRealisees != null && intervention.actionsRealisees.length > 400) {
            errors.actionsRealisees = "La taille du champ actionsRealisees est trop grande"
        }

        if (intervention.callOrigin != null && intervention.callOrigin.length > 100) {
            errors.callOrigin = "La taille du champ callOrigin est trop grande"
        }
        if (intervention.otherCallOrigin != null && intervention.otherCallOrigin.length > 100) {
            errors.otherCallOrigin = "La taille du champ callOrigin est trop grande"
        }
        if (intervention.callTime != null && intervention.callTime.length > 50) {
            errors.callTime = "La taille du champ callTime est trop grande"
        }

        if (intervention.commune != null && intervention.commune.length > 100) {
            errors.commune = "La taille du champ commune est trop grande"
        }

        if (intervention.conditionMeteo != null && intervention.conditionMeteo.length > 50) {
            errors.conditionMeteo = "La taille du champ conditionMeteo est trop grande"
        }

        if (intervention.equipe != null && intervention.equipe.length > 100) {
            errors.equipe = "La taille du champ equipe est trop grande"
        }

        if (intervention.etatChaussee != null && intervention.etatChaussee.length > 50) {
            errors.etatChaussee = "La taille du champ etatChaussee est trop grande"
        }

        if (intervention.heureArriveeSite != null && intervention.heureArriveeSite.length > 50) {
            errors.heureArriveeSite = "La taille du champ heureArriveeSite est trop grande"
        }

        if (intervention.heureDepartSite != null && intervention.heureDepartSite.length > 50) {
            errors.heureDepartSite = "La taille du champ heureDepartSite est trop grande"
        }

        if (intervention.heureDebut != null && intervention.heureDebut.length > 50) {
            errors.heureDebut = "La taille du champ heureDebut est trop grande"
        }

        if (intervention.heureFin != null && intervention.heureFin.length > 50) {
            errors.heureFin = "La taille du champ heureFin est trop grande"
        }

        if (intervention.intervenants != null && intervention.intervenants.length > 400) {
            errors.intervenants = "La taille du champ intervenants est trop grande"
        }

        if (intervention.localisation != null && intervention.localisation.length > 255) {
            errors.localisation = "La taille du champ localisation est trop grande"
        }

        if (intervention.moyensHumains != null && intervention.moyensHumains.length > 400) {
            errors.moyensHumains = "La taille du champ moyensHumains est trop grande"
        }

        if (intervention.moyensMateriels != null && intervention.moyensMateriels.length > 400) {
            errors.moyensMateriels = "La taille du champ moyensMateriels est trop grande"
        }

        if (intervention.precisionNiveau1 != null && intervention.precisionNiveau1.length > 50) {
            errors.precisionNiveau1 = "La taille du champ precisionNiveau1 est trop grande"
        }

        if (intervention.precisionNiveau2 != null && intervention.precisionNiveau2.length > 50) {
            errors.precisionNiveau2 = "La taille du champ precisionNiveau2 est trop grande"
        }

        if (intervention.sens != null && intervention.sens.length > 50) {
            errors.sens = "La taille du champ sens est trop grande"
        }

        if (intervention.typeIntervention != null && intervention.typeIntervention.length > 50) {
            errors.typeIntervention = "La taille du champ typeIntervention est trop grande"
        }
        
        if (intervention.otherTypeIntervention != null && intervention.otherTypeIntervention.length > 100) {
            errors.otherTypeIntervention = "La taille du champ typeIntervention est trop grande"
        }

        if (intervention.vehiculesImpliques != null && intervention.vehiculesImpliques.length > 400) {
            errors.vehiculesImpliques = "La taille du champ vehiculesImpliques est trop grande"
        }

        if (intervention.voie != null && intervention.voie.length > 100) {
            errors.voie = "La taille du champ voie est trop grande"
        }

        return errors
    },
    canSeeIntervention(interventionId) {
        return Api().get("/intervention/cansee/" + interventionId).then(res => {
            return res.data;
        });
    },
    canUpdateIntervention(interventionId) {
        return Api().get("/intervention/canupdate/" + interventionId).then(res => {
            return res.data;
        });
    },
    downloadInterventionListExcel(filters) {
        return Api().post("/interventions/excel", filters, {responseType: 'blob'}).then(res => {
            downloadData(res);
        });
    },
    downloadInterventionListExcelComplet(filters) {
        return Api().post("/interventions/excelcomplet", filters, {responseType: 'blob'}).then(res => {
            downloadData(res);
        });
    },
    downloadInterventionListExcelCustom(filters) {
        return Api().post("/interventions/excelcustom", filters, {responseType: 'blob'}).then(res => {
            downloadData(res);
        });
    },
    formatRes(e) {
        if (null != e?.callDate) e.callDate = new Date(e.callDate);
        return e;
    },
    downloadInterventionPdf(interventionId) {
        return Api().get("/intervention/pdf/" + interventionId, {responseType: 'blob'}).then(res => {
            downloadData(res);
        });
    },
    deleteList(selectedInterventions) {
        return Api().delete("/interventions", {data: selectedInterventions});
    }
}