export default {
    data() {
        return {
            precision1IntemperieConst: [
                {label: this.$t('precisions.1.intemperie.vent_violent'), value: 'VENT_VIOLENT'},
                {label: this.$t('precisions.1.intemperie.pluie_violente'), value: 'PLUIE_VIOLENTE'},
                {label: this.$t('precisions.1.intemperie.chutes_de_neige_violentes'), value: 'CHUTES_DE_NEIGE_VIOLENTES'},
                {label: this.$t('precisions.1.intemperie.inondation'), value: 'INONDATION'},
                {label: this.$t('precisions.1.intemperie.incendie'), value: 'INCENDIE'},
            ],
        }
    },
    methods: {
        displayForPrecision1IntemperieConst(value) {
            let result = this.precision1IntemperieConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision1IntemperieConst() {
            return this.precision1IntemperieConst;
        }
    }
}