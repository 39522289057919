export default {
    data() {
        return {
            precision2ObstaclesConst: [
                {label: this.$t('precisions.2.obstacles.objets'), value: 'OBJETS'},
                {label: this.$t('precisions.2.obstacles.cones'), value: 'CONES'},
                {label: this.$t('precisions.2.obstacles.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForPrecision2ObstaclesConst(value) {
            let result = this.precision2ObstaclesConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision2ObstaclesConst() {
            return this.precision2ObstaclesConst;
        }
    }
}