export default {
    data() {
        return {
            voieConst: [
                {label: this.$t('voie.bau'), value: 'BAU'},
                {label: this.$t('voie.voie_lente'), value: 'VOIE_LENTE'},
                {label: this.$t('voie.voie_rapide'), value: 'VOIE_RAPIDE'},
                {label: this.$t('voie.voie_mediane'), value: 'VOIE_MEDIANE'},
                {label: this.$t('voie.tpc'), value: 'TPC'},
                {label: this.$t('voie.accotement'), value: 'ACCOTEMENT'},
            ],
        }
    },
    methods: {
        displayForVoieConst(value) {
            let result = this.voieConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getVoieConst() {
            return this.voieConst;
        }
    }
}