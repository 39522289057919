export default {
    data() {
        return {
            precision1BalisageConst: [
                {label: this.$t('precisions.1.balisage.introduction_vehicule_balisage'), value: 'INTRODUCTION_VEHICULE_BALISAGE'},
                {label: this.$t('precisions.1.balisage.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForPrecision1BalisageConst(value) {
            let result = this.precision1BalisageConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision1BalisageConst() {
            return this.precision1BalisageConst;
        }
    }
}