export default {
    data() {
        return {
            precision1ChausseeConst: [
                {label: this.$t('precisions.1.chaussee.rebouchage_nid_poule'), value: 'REBOUCHAGE_NID_POULE'},
                {label: this.$t('precisions.1.chaussee.epandage_absorbant_chaussee'), value: 'EPANDAGE_ABSORBANT_CHAUSSEE'},
                {label: this.$t('precisions.1.chaussee.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForPrecision1ChausseeConst(value) {
            let result = this.precision1ChausseeConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision1ChausseeConst() {
            return this.precision1ChausseeConst;
        }
    }
}