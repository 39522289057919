export default {
    data() {
        return {
            precision2EvenementsInopinesConst: [
                {label: this.$t('precisions.2.evenements_inopines.inondation'), value: 'INONDATION'},
                {label: this.$t('precisions.2.evenements_inopines.eboulement'), value: 'EBOULEMENT'},
                {label: this.$t('precisions.2.evenements_inopines.manifestation'), value: 'MANIFESTATION'},
                {label: this.$t('precisions.2.evenements_inopines.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForPrecision2EvenementsInopinesConst(value) {
            let result = this.precision2EvenementsInopinesConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision2EvenementsInopinesConst() {
            return this.precision2EvenementsInopinesConst;
        }
    }
}