export default {
    data() {
        return {
            precision1AnimauxConst: [
                {label: this.$t('precisions.1.animaux.animal_errant'), value: 'ANIMAL_ERRANT'},
                {label: this.$t('precisions.1.animaux.animal_mort'), value: 'ANIMAL_MORT'},
            ],
        }
    },
    methods: {
        displayForPrecision1AnimauxConst(value) {
            let result = this.precision1AnimauxConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision1AnimauxConst() {
            return this.precision1AnimauxConst;
        }
    }
}