export default {
    data() {
        return {
            sensConst: [
                {label: this.$t('sens.w'), value: 'W'},
                {label: this.$t('sens.y'), value: 'Y'},
                {label: this.$t('sens.int'), value: 'INT'},
                {label: this.$t('sens.ext'), value: 'EXT'},
            ],
        }
    },
    methods: {
        displayForSensConst(value) {
            let result = this.sensConst.find(el => el.value === value);
            return result ? result.label : '';
        }
    }
}