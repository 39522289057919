export default {
    data() {
        return {
            precision2VehiculeArreteConst: [
                {label: this.$t('precisions.2.vehicule_arrete.vehicule_arrete'), value: 'VEHICULE_ARRETE'},
                {label: this.$t('precisions.2.vehicule_arrete.vehicule_abandonne'), value: 'VEHICULE_ABANDONNE'},
                {label: this.$t('precisions.2.vehicule_arrete.vehicule_en_feu'), value: 'VEHICULE_EN_FEU'},
            ],
        }
    },
    methods: {
        displayForPrecision2VehiculeArreteConst(value) {
            let result = this.precision2VehiculeArreteConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision2VehiculeArreteConst() {
            return this.precision2VehiculeArreteConst;
        }
    }
}