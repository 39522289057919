import precision1AccidentConst from "@mixins/const/precision1AccidentConst";
import precision1IntemperieConst from "@mixins/const/precision1IntemperieConst";
import precision1BouchonConst from "@mixins/const/precision1BouchonConst";
import precision1ObstacleMobileFixeConst from "@mixins/const/precision1ObstacleMobileFixeConst";
import precision1BalisageConst from "@mixins/const/precision1BalisageConst";
import precision1ChausseeConst from "@mixins/const/precision1ChausseeConst";
import precision1AnimauxConst from "@mixins/const/precision1AnimauxConst";
import precision2ChausseeGlissanteProduitsRouteConst from "@mixins/const/precision2ChausseeGlissanteProduitsRouteConst";
import precision2EvenementsInopinesConst from "@mixins/const/precision2EvenementsInopinesConst";
import precision2ObstaclesConst from "@mixins/const/precision2ObstaclesConst";
import precision2PietonCyclisteConst from "@mixins/const/precision2PietonCyclisteConst";
import precision2VehiculeArreteConst from "@mixins/const/precision2VehiculeArreteConst";
import precision2VehiculeGenantConst from "@mixins/const/precision2VehiculeGenantConst";
import precision2AnimauxConst from "@mixins/const/precision2AnimauxConst";

export default {
    mixins: [
        precision1AccidentConst,
        precision1IntemperieConst,
        precision1BouchonConst,
        precision1ObstacleMobileFixeConst,
        precision1BalisageConst,
        precision1ChausseeConst,
        precision1AnimauxConst,
        precision2ChausseeGlissanteProduitsRouteConst,
        precision2EvenementsInopinesConst,
        precision2ObstaclesConst,
        precision2PietonCyclisteConst,
        precision2VehiculeArreteConst,
        precision2VehiculeGenantConst,
        precision2AnimauxConst
    ],
    methods: {
        hasPrecision1(typeIntervention) {
            return this.getPrecision1Const(typeIntervention).length > 0;
        },
        hasPrecision2(precisionTypeIntervention1) {
            return this.getPrecision2Const(precisionTypeIntervention1).length > 0;
        },
        getPrecision1Const(typeIntervention) {
            switch (typeIntervention) {
                case 'ACCIDENT':
                    return this.getPrecision1AccidentConst();
                case 'INTEMPERIE':
                    return this.getPrecision1IntemperieConst();
                case 'BOUCHON':
                    return this.getPrecision1BouchonConst();
                case 'OBSTACLE_MOBILE_FIXE':
                    return this.getPrecision1ObstacleMobileFixeConst();
                case 'BALISAGE':
                    return this.getPrecision1BalisageConst();
                case 'CHAUSSEE':
                    return this.getPrecision1ChausseeConst();
                case 'ANIMAUX':
                    return this.getPrecision1AnimauxConst();
                default:
                    return [];
            }
        },
        getPrecision2Const(precisionTypeIntervention1) {
            switch (precisionTypeIntervention1) {
                case 'CHAUSSEE_GLISSANTE_PRODUITS_ROUTE':
                    return this.getPrecision2ChausseeGlissanteProduitsRouteConst();
                case 'VEHICULE_ARRETE':
                    return this.getPrecision2VehiculeArreteConst();
                case 'PIETON_CYCLISTE':
                    return this.getPrecision2PietonCyclisteConst();
                case 'EVENEMENTS_INOPINES':
                    return this.getPrecision2EvenementsInopinesConst();
                case 'OBSTACLES':
                    return this.getPrecision2ObstaclesConst();
                case 'VEHICULE_GENANT':
                    return this.getPrecision2VehiculeGenantConst();
                case 'ANIMAL_ERRANT':
                case 'ANIMAL_MORT':
                    return this.getPrecision2AnimauxConst();
                default:
                    return [];
            }
        },
        isPrecision1RelatedToTypeIntervention(typeIntervention, typePrecision1) {
            return this.getPrecision1Const(typeIntervention).find(el => el.value === typePrecision1) !== undefined;
        },
        isPrecision2RelatedToPrecision1(typePrecision1, typePrecision2) {
            return this.getPrecision2Const(typePrecision1).find(el => el.value === typePrecision2) !== undefined;
        },
        isPrecision2RelatedToTypeIntervention(typeIntervention, typePrecision1, typePrecision2) {
            return this.isPrecision1RelatedToTypeIntervention(typeIntervention, typePrecision1) && this.isPrecision2RelatedToPrecision1(typePrecision1, typePrecision2);
        },
        hasTypeInterventionAnyPrecision2(typeIntervention) {
            let precision1Const = this.getPrecision1Const(typeIntervention);
            for (let i = 0; i < precision1Const.length; i++) {
                if (this.hasPrecision2(precision1Const[i].value)) {
                    return true;
                }
            }
        }
    }
}