export default {
    data() {
        return {
            origineAppelConst: [
                {label: this.$t('origine_appel.ost'), value: 'OST'},
                {label: this.$t('origine_appel.police'), value: 'POLICE'},
                {label: this.$t('origine_appel.gendarmerie'), value: 'GENDARMERIE'},
                {label: this.$t('origine_appel.pompiers'), value: 'POMPIERS'},
                {label: this.$t('origine_appel.depanneurs'), value: 'DEPANNEURS'},
                {label: this.$t('origine_appel.autres_gestionnaires_reseau'), value: 'AUTRES_GESTIONNAIRES_RESEAU'},
                {label: this.$t('origine_appel.usagers'), value: 'USAGERS'},
                {label: this.$t('origine_appel.ri'), value: 'RI'},
                {label: this.$t('origine_appel.autre'), value: 'AUTRE'},
            ],
        }
    },
    methods: {
        displayForOrigineAppelConst(value) {
            let result = this.origineAppelConst.find(el => el.value === value);
            return result ? result.label : '';
        }
    }
}