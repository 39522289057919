export default {
    data() {
        return {
            precision2AnimauxConst: [
                {label: this.$t('precisions.2.animaux.sauvage'), value: 'SAUVAGE'},
                {label: this.$t('precisions.2.animaux.domestique'), value: 'DOMESTIQUE'},
                {label: this.$t('precisions.2.animaux.elevage'), value: 'ELEVAGE'},
            ],
        }
    },
    methods: {
        displayForPrecision2AnimauxConst(value) {
            let result = this.precision2AnimauxConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision2AnimauxConst() {
            return this.precision2AnimauxConst;
        }
    }
}