export default {
    data() {
        return {
            precision2PietonCyclisteConst: [
                {label: this.$t('precisions.2.pieton_cycliste.pieton'), value: 'PIETON'},
                {label: this.$t('precisions.2.pieton_cycliste.cycliste'), value: 'CYCLISTE'},
                {label: this.$t('precisions.2.pieton_cycliste.trotinette'), value: 'TROTINETTE'},
                {label: this.$t('precisions.2.pieton_cycliste.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForPrecision2PietonCyclisteConst(value) {
            let result = this.precision2PietonCyclisteConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getPrecision2PietonCyclisteConst() {
            return this.precision2PietonCyclisteConst;
        }
    }
}