import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/axeinterventions", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/axeintervention/" + id).then(res => this.formatRes(res.data));
    },
    create(axeIntervention) {
        return Api().post("/axeintervention", axeIntervention).then(res => this.formatRes(res.data));
    },
    update(axeIntervention) {
        return Api().put("/axeintervention", axeIntervention).then(res => this.formatRes(res.data));
    },
    delete(axeIntervention) {
        return Api().delete("/axeintervention/" + axeIntervention.id).then(() => axeIntervention);
    },
    formatRes(e) {
        return e;
    }
}