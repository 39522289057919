export default {
    data() {
        return {
            typeInterventionConst: [
                {label: this.$t('type_intervention.accident'), value: 'ACCIDENT'},
                {label: this.$t('type_intervention.animaux'), value: 'ANIMAUX'},
                {label: this.$t('type_intervention.bouchon'), value: 'BOUCHON'},
                {label: this.$t('type_intervention.intemperie'), value: 'INTEMPERIE'},
                {label: this.$t('type_intervention.obstacle_mobile_fixe'), value: 'OBSTACLE_MOBILE_FIXE'},
                {label: this.$t('type_intervention.vehicule_en_panne'), value: 'VEHICULE_EN_PANNE'},
                {label: this.$t('type_intervention.balisage'), value: 'BALISAGE'},
                {label: this.$t('type_intervention.chaussee'), value: 'CHAUSSEE'},
                {label: this.$t('type_intervention.autre'), value: 'AUTRE'},
            ],
        }
    },
    methods: {
        displayForTypeInterventionConst(value) {
            let result = this.typeInterventionConst.find(el => el.value === value);
            return result ? result.label : '';
        }
    }
}